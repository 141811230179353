import './index.css'

export default function PageTip({info}) {
  return (
    <div className='page-tip'>
      <div className='page-title'>{info.title}</div>
      <div className='page-text'>{info.tip}</div>
    </div>
  );
}
