import './index.css';
import { useNavigate } from "react-router-dom";

export default function FilterButton() {
    const navigate = useNavigate();

    return (
        <div className='filter' onClick={()=> {navigate(`/s`);}}>
            <i className='bx bx-star bx-slg'></i>
        </div>
    )
}