const storage = {

    setArray : (key, value) => {
        localStorage.setItem(key, JSON.stringify(value))
    },
    
    getArray: (key) => {
        const value = localStorage.getItem(key);
        return JSON.parse(value) || []
    }
    
}

export default storage