import { useNavigate } from "react-router-dom";
import imgs from "./../../assets/imgs";
import "./index.css";

export default function CharGrid({ map: dic }) {
  const navigate = useNavigate();
  const items = Object.entries(dic).map(([key, value]) => (
    <div
      className="item"
      key={key}
      onClick={() => {
        navigate(`/l?w=${key}`);
      }}
    >
      <img className="word-img" src={imgs[key]} alt="word img"></img>
      <div>{`${value} ${value > 1 ? "words" : "word"}`}</div>
    </div>
  ));

  return <ul className="grid">{items}</ul>;
}
