import "./index.css";

export default function Footer() {
  const now = new Date();
  return (
    <footer>
      <div>©{now.getFullYear()} Englishi by Sediment</div>
      <div className="social">
        <i className='bx bxl-instagram-alt bx-tada-hover'></i>
        <i className='bx bxl-twitter bx-tada-hover'></i>
        <i className='bx bxl-discord-alt bx-tada-hover'></i>
      </div>
    </footer>
  );
}
