import React, { useRef, useState } from "react";
import PageTip from "../PageTip";
import storage from "../../utils";
import "./index.css";
import md5 from "md5";

export default function WordList() {
  const starWords = storage.getArray("starWords")
  const wordAudioRef = useRef(null);
  const [list, setList] = useState(starWords);

  const onPlay = (word) => {
    const wordMD5 = md5(word);
    const one2two = wordMD5.slice(0, 2);
    const three2four = wordMD5.slice(2, 4);
    wordAudioRef.current.src = `http://res.iciba.com/resource/amp3/1/0/${one2two}/${three2four}/${wordMD5}.mp3`;
    wordAudioRef.current.play();
  };

  const onDelete = (id) => {
    let filterList = list.filter(item => item.id !== id)
    setList(filterList);
    storage.setArray('starWords', filterList);
  };

  const items = list.length > 0 ? list.map((item) => {
    return (
      <div className="word-item" key={item.id} id={item.id}>
        <div
          className="remove-star"
          onClick={() => {
            onDelete(item.id);
          }}
        >
          <i className='bx bxs-minus-circle'></i>
        </div>
        <div className="word-text">{item.w}</div>
        <div className="type">{item.t}</div>
        <div
          className="play-audio"
          onClick={() => {
            onPlay(item.w);
          }}
        >
          <i className='bx bxs-volume-full'></i>
        </div>
        <div className="search">
          <a
            href={`https://www.ldoceonline.com/dictionary/${item.w}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className='bx bxs-send'></i>
          </a>
        </div>
        <div className="level">
          <span>{item.l}</span>
        </div>
      </div>
    );
  }) : (<PageTip info={{title: "Woops!", tip:'Nothing'}}></PageTip>);

  return (
    <div>
      <ul className="word-list">{items}</ul>
      <div></div>
      <audio id="word-audio" src="" ref={wordAudioRef}></audio>
    </div>
  );
}
