import React, { useRef } from "react";
import storage from "../../utils";
import "./index.css";
import md5 from "md5";

export default function WordList({ list, alpha = "A" }) {
  const wordAudioRef = useRef(null);
  const refList = useRef(null)

  const onPlay = (word) => {
    const wordMD5 = md5(word);
    const one2two = wordMD5.slice(0, 2);
    const three2four = wordMD5.slice(2, 4);
    wordAudioRef.current.src = `http://res.iciba.com/resource/amp3/1/0/${one2two}/${three2four}/${wordMD5}.mp3`;
    wordAudioRef.current.play();
  };

  const onStar = (word) => {
    const map = {}
    refList.current.childNodes.forEach(child => {
      map[child.id] = child;
    })
    
    const currentItem = map[word.id];
    if(currentItem.firstChild.className === "dark-star") {
      currentItem.firstChild.className = "light-star"
    } else {
      currentItem.firstChild.className = "dark-star"
    }

    let starWords = storage.getArray('starWords');
    if(starWords.some(item => item.id === word.id)) {
      const filterList = starWords.filter(item => item.id !== word.id);
      storage.setArray('starWords', filterList)
    } else {
      starWords.push(word);
      storage.setArray('starWords', starWords)

    }
  };

  const starWordsIDs = storage.getArray('starWords').map(item => item.id)

  const items = list[alpha].map((item) => {
    return (
      <div className="word-item" key={item.id} id={item.id}>
        <div
          className={starWordsIDs.includes(item.id) ? 'light-star' : 'dark-star'}
          onClick={() => {
            onStar(item);
          }}
        >
          <i className='bx bxs-star'></i>
        </div>
        <div className="word-text">{item.w}</div>
        <div className="type">{item.t}</div>
        <div
          className="play-audio"
          onClick={() => {
            onPlay(item.w);
          }}
        >
          <i className='bx bxs-volume-full'></i>
        </div>
        <div className="search">
          <a
            href={`https://www.ldoceonline.com/dictionary/${item.w}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className='bx bxs-send'></i>
          </a>
        </div>
        <div className="level">
          <span>{item.l}</span>
        </div>
      </div>
    );
  });

  return (
    <div>
      <ul className="word-list" ref={refList}>{items}</ul>
      <div></div>
      <audio id="word-audio" src="" ref={wordAudioRef}></audio>
    </div>
  );
}
