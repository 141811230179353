import logo from "./logo.png";
import { useNavigate } from "react-router-dom";
import "./index.css";

export default function Header() {
  const navigate = useNavigate();

  return (
    <header className="header">
       <img src={logo} className="logo" alt="logo" onClick={() => {navigate(`/`)}}/>
       <div className="title">
        <p>Englishi</p>
        <p>longman 3000</p>
      </div>
    </header>
  );
}
