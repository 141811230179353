// import A from "./a.png";
// import B from "./b.png";
// import C from "./c.png";
// import D from "./d.png";
// import E from "./e.png";
// import F from "./f.png";
// import G from "./g.png";
// import H from "./h.png";
// import I from "./i.png";
// import J from "./j.png";
// import K from "./k.png";
// import L from "./l.png";
// import M from "./m.png";
// import N from "./n.png";
// import O from "./o.png";
// import P from "./p.png";
// import Q from "./q.png";
// import R from "./r.png";
// import S from "./s.png";
// import T from "./t.png";
// import U from "./u.png";
// import V from "./v.png";
// import W from "./w.png";
// import X from "./x.png";
// import Y from "./y.png";
// import Z from "./z.png";

//  const imgs = {
//   A:"https://imgtu.com/i/LoGrWt",
//   B:"https://imgtu.com/i/LoGDJI",
//   C:"https://imgtu.com/i/LoGBFA",
//   D:"https://imgtu.com/i/LoGySP",
//   E:"https://imgtu.com/i/LoG6Qf",
//   F:"https://imgtu.com/i/LoGcy8",
//   G:"https://imgtu.com/i/LoGgOS",
//   H:"https://imgtu.com/i/LoGReg",
//   I:"https://imgtu.com/i/LoGWwQ",
//   J:"https://imgtu.com/i/LoGfoj",
//   K:"https://imgtu.com/i/LoG4Fs",
//   L:"https://imgtu.com/i/LoG5Yn",
//   M:"https://imgtu.com/i/LoGIWq",
//   N:"https://imgtu.com/i/LoGTS0",
//   O:"https://imgtu.com/i/LoG7lV",
//   P:"https://imgtu.com/i/LoGHyT",
//   Q:"https://imgtu.com/i/LoGbOU",
//   R:"https://imgtu.com/i/LoGLmF",
//   S:"https://imgtu.com/i/LoGOw4",
//   T:"https://imgtu.com/i/LoGXTJ",
//   U:"https://imgtu.com/i/LoGvk9",
//   V:"https://imgtu.com/i/LoGxYR",
//   W:"https://imgtu.com/i/LoGzf1",
//   X:"https://imgtu.com/i/LoJpSx",
//   Y:"https://imgtu.com/i/LoJ9l6",
//   Z:"https://imgtu.com/i/LoJC6K",
// };

 const imgs = {
  A:"https://s1.ax1x.com/2022/04/25/LoGrWt.png",
  B:"https://s1.ax1x.com/2022/04/25/LoGDJI.png",
  C:"https://s1.ax1x.com/2022/04/25/LoGBFA.png",
  D:"https://s1.ax1x.com/2022/04/25/LoGySP.png",
  E:"https://s1.ax1x.com/2022/04/25/LoG6Qf.png",
  F:"https://s1.ax1x.com/2022/04/25/LoGcy8.png",
  G:"https://s1.ax1x.com/2022/04/25/LoGgOS.png",
  H:"https://s1.ax1x.com/2022/04/25/LoGReg.png",
  I:"https://s1.ax1x.com/2022/04/25/LoGWwQ.png",
  J:"https://s1.ax1x.com/2022/04/25/LoGfoj.png",
  K:"https://s1.ax1x.com/2022/04/25/LoG4Fs.png",
  L:"https://s1.ax1x.com/2022/04/25/LoG5Yn.png",
  M:"https://s1.ax1x.com/2022/04/25/LoGIWq.png",
  N:"https://s1.ax1x.com/2022/04/25/LoGTS0.png",
  O:"https://s1.ax1x.com/2022/04/25/LoG7lV.png",
  P:"https://s1.ax1x.com/2022/04/25/LoGHyT.png",
  Q:"https://s1.ax1x.com/2022/04/25/LoGbOU.png",
  R:"https://s1.ax1x.com/2022/04/25/LoGLmF.png",
  S:"https://s1.ax1x.com/2022/04/25/LoGOw4.png",
  T:"https://s1.ax1x.com/2022/04/25/LoGXTJ.png",
  U:"https://s1.ax1x.com/2022/04/25/LoGvk9.png",
  V:"https://s1.ax1x.com/2022/04/25/LoGxYR.png",
  W:"https://s1.ax1x.com/2022/04/25/LoGzf1.png",
  X:"https://s1.ax1x.com/2022/04/25/LoJpSx.png",
  Y:"https://s1.ax1x.com/2022/04/25/LoJ9l6.png",
  Z:"https://s1.ax1x.com/2022/04/25/LoJC6K.png",
};
export default  imgs
