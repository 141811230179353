import "./App.css";
import { Routes, Route, useSearchParams, useLocation } from "react-router-dom";

import data from "./data/data.json";

import Header from "./components/Header";
import Footer from "./components/Footer";
import CharGrid from "./components/CharGrid";
import WordList from "./components/WordList";
import StarList from "./components/StarList";
import FilterButton from "./components/FilterButton";
import PageTip from "./components/PageTip";

function App() {
  let location = useLocation();
  let [searchParams] = useSearchParams();
  let alpha = "";
  if (location.pathname === "/l") {
    alpha = searchParams.get("w") || "A";
  }

  return (
    <div className="App">
      <Header></Header>
      <main>
        <Routes>
          <Route path="/" element={<CharGrid map={data.map}></CharGrid>} />
          <Route
            path="/l"
            element={<WordList alpha={alpha} list={data.list} />}
          />
          <Route
            path="/s"
            element={<StarList list={data.list} />}
          />
          <Route path="*" element={<PageTip info={{title: '404', tip:'What Are You Doing?'}} />} />
        </Routes>
      </main>
      <FilterButton></FilterButton>
      <Footer></Footer>
    </div>
  );
}

export default App;
